<template>
  <MainCardsBlock
    v-element-visibility="(isVisibility) => isVisibility && sendHomeShowShelf(label, {
      shelf_number: shelfNumber,
    })"
    title="Полезные навыки&nbsp;&mdash; <br> бесплатно и&nbsp;прямо сейчас"
    description="Узнавайте новое и&nbsp;сразу применяйте знания на&nbsp;практике"
    button-text="К бесплатным курсам"
    button-link="/catalog/?free"
    :cards="freeCourses"
    @click:card="(event: any) => {
      sendHomeClickShelf(label, {
        shelf_number: shelfNumber
      })
      sendHomeClickCard({
        shelf_number: shelfNumber,
        card_number: event.cardNumber,
        nomenclature_id: event.nomenclatureId,
        nomenclature_type: event.nomenclatureType,
      })
    }"
    @click:link="sendHomeClickLink(label, {
      link: '/catalog/?free'
    })"
    @visible:card="(event: any) => sendHomeShowCard({
      shelf_number: shelfNumber,
      card_number: event.cardNumber,
      nomenclature_id: event.nomenclatureId,
      nomenclature_type: event.nomenclatureType,
    })"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { vElementVisibility } from '@vueuse/components'

  import { useMainStore } from '@/store/main'

  import { sendHomeShowShelf, sendHomeClickShelf, sendHomeClickCard, sendHomeClickLink, sendHomeShowCard } from '@/pages/index/analytics'

  const label = 'free_courses'
  const shelfNumber = 1

  const mainStore = useMainStore()
  const { freeCourses } = storeToRefs(mainStore)

  useAsyncData(
    'getFreeCourses',
    () => mainStore.getFreeCourses(),
  )
</script>
